<template>
  <v-container fill-height class="Login">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <v-card width="700" max-width="95%" class="d-flex pt-12 flex-column">
          <p class="mb-12 text-center">
            <img alt="logo" :src="require('@/assets/logo-tooly.svg')" width="150px" />
          </p>

          <v-card-title class="headline">
            {{ $t('reset-password.title') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('reset-password.subtitle') }}
          </v-card-subtitle>

          <v-card-text>
            <form @submit.prevent="submit">
              <v-text-field
                v-show="!Boolean(queryToken)"
                v-model="token"
                class="mb-2"
                data-e2e="reset-token"
                :data-vv-as="$t('reset-password.form.token')"
                data-vv-name="token"
                data-vv-validate-on="blur"
                dense
                :error-messages="errors.collect('token')"
                :label="$t('reset-password.form.token')"
                outlined
                type="text"
                v-validate="'required'"
              />

              <v-text-field
                v-model="password"
                class="mb-2"
                data-e2e="reset-password"
                :data-vv-as="$t('reset-password.form.password')"
                data-vv-name="password"
                dense
                :error-messages="errors.collect('password')"
                :label="$t('reset-password.form.password')"
                outlined
                type="password"
                v-validate="'required|password'"
              />

              <v-text-field
                v-model="confirmation"
                data-e2e="reset-confirmation"
                :data-vv-as="$t('reset-password.form.confirmation')"
                data-vv-name="confirmation"
                dense
                :error-messages="errors.collect('confirmation')"
                :label="$t('reset-password.form.confirmation')"
                outlined
                type="password"
                v-validate="'required|password'"
              />

              <p v-if="mismatch" class="red--text">
                {{ $t('reset-password.form.error') }}
              </p>

              <v-card-actions>
                <v-spacer />
                <v-btn type="submit" color="blue" dark depressed>{{ $t('reset-password.form.submit') }}</v-btn>
              </v-card-actions>
            </form>
          </v-card-text>

          <v-row v-if="error" justify="center">
            <v-alert type="error">
              {{ error }}
            </v-alert>
          </v-row>
        </v-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { RESET_PASSWORD } from '@/graphql/User/ResetPassword'

export default {
  name: 'ResetPassword',
  $_veeValidate: {
    validator: 'new'
  },
  data() {
    return {
      password: null,
      confirmation: null,
      token: this.$route.query.token || null,
      mismatch: false,
      error: null
    }
  },
  computed: {
    queryToken() {
      return this.$route.query.token
    }
  },
  methods: {
    async submit() {
      this.mismatch = false

      const valid = await this.$validator.validateAll()

      if (!valid) return

      if (this.password !== this.confirmation) {
        this.mismatch = true

        return
      }

      try {
        await this.$apollo.mutate({
          mutation: RESET_PASSWORD,
          variables: {
            password: this.password,
            crypted: this.token
          }
        })

        this.$router.push({ name: 'Login' })
      } catch (error) {
        console.error(error)

        this.error = error.message

        setTimeout(() => {
          this.error = null
        }, 6000)
      }
    }
  }
}
</script>
