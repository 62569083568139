import gql from 'graphql-tag'

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $crypted: String!) {
    resetPassword(password: $password, crypted: $crypted)
  }
`

export const GENERATE_RESET_PASSWORD = gql`
  mutation generateResetPassword($userUuid: GUID!, $sendEmail: Boolean) {
    generateResetPassword(userUuid: $userUuid, sendEmail: $sendEmail)
  }
`
