<template>
  <v-container fill-height class="Login">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <v-card width="700" max-width="95%" class="d-flex py-12 flex-column">
          <v-card width="400" elevation="0" class="d-flex flex-column mx-auto text-center">
            <p class="mb-12 text-center">
              <img alt="logo" :src="require('@/assets/logo-tooly.svg')" width="150px" />
            </p>
            <div class="mb-12 headline">{{ $t('loginPage.title') }}</div>
            <v-btn href="/auth/ad_login" color="#dc3e15" dark depressed class="subtitle-2 text-none py-5">
              <v-icon left>mdi-office</v-icon>{{ $t`connect-via-365-office` }}
            </v-btn>

            <v-expansion-panels v-model="loginPanel" accordion class="mt-12" flat readonly>
              <v-expansion-panel>
                <v-expansion-panel-header hide-actions class="Login-expandHeader">
                  <p class="lighten-2 font-italic text-center">
                    {{ $t('loginPage.externalSentence1') }}<br />
                    {{ $t('loginPage.externalSentence2') }}
                    <span @click="toggleExpand" class="Login-buttonToExpandLogin" v-cy="$cy.login.expand">
                      {{ $t('loginPage.buttonExpandLogin') }}
                    </span>
                  </p>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                  <form @submit.prevent="login">
                    <v-text-field
                      v-model="email"
                      v-cy="$cy.login.login"
                      :data-vv-as="$t`email`"
                      data-vv-name="email"
                      data-vv-validate-on="blur"
                      dense
                      :error-messages="errors.collect('email')"
                      :label="$t('loginPage.loginField.label')"
                      outlined
                      type="email"
                      v-validate="'required|email'"
                    />
                    <v-text-field
                      v-model="password"
                      @click:append="showPassword = !showPassword"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      class="mt-2"
                      v-cy="$cy.login.password"
                      :data-vv-as="$t`password`"
                      data-vv-name="password"
                      data-vv-validate-on="blur"
                      dense
                      :error-messages="errors.collect('password')"
                      :label="$t('loginPage.passwordField.label')"
                      outlined
                      :type="showPassword ? 'text' : 'password'"
                      v-validate="'required'"
                    />
                    <v-btn
                      :disabled="!activeSendButton"
                      v-cy="$cy.login.button"
                      color="primary"
                      width="100%"
                      depressed
                      class="mt-2"
                      type="submit"
                    >
                      {{ $t('loginPage.submitButton') }}
                    </v-btn>
                  </form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
          <v-row>
            <v-spacer />
            <v-col cols="10">
              <v-alert v-if="error" type="error" v-cy="$cy.login.errorContainer" class="mt-2" dense>{{
                $t`wrong-mail-password`
              }}</v-alert>
            </v-col>
            <v-spacer />
          </v-row>
        </v-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Login',

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    email: '',
    loginPanel: null,
    password: '',
    showPassword: false,
    validated: false,
    error: false
  }),

  computed: {
    activeSendButton() {
      return Boolean(this.email) && Boolean(this.password)
    }
  },

  methods: {
    async login() {
      const valid = await this.$validator.validateAll()
      if (!valid) return

      try {
        await axios.post(`${this.$baseUrl}/auth/local_login`, {
          email: this.email,
          password: this.password
        })

        let loginRedirect = { name: 'Home' }
        const cookie = this.$cookies.get('loginRedirect')

        if (cookie) {
          loginRedirect = cookie

          if (loginRedirect.path) {
            delete loginRedirect.path
          }

          this.$cookies.remove('loginRedirect')
        }

        this.$router.push(loginRedirect)
      } catch (error) {
        this.error = true
      }
    },
    toggleExpand() {
      this.loginPanel === 0 ? (this.loginPanel = null) : (this.loginPanel = 0)
    }
  }
}
</script>

<style scoped lang="scss">
.Login {
  .office-button {
    text-transform: none;
  }

  .horizontal-line {
    border-bottom: 1px solid darkgray;
    height: 1px;
  }
}

.Login-expandHeader {
  cursor: initial;
}

.Login-buttonToExpandLogin {
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    font-weight: 900;
  }
}
</style>
